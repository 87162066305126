import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { WindowIcon } from '@heroicons/react/24/solid'

const people = [
  {
    name: 'NBI Financial Group',
    role: 'Web Development and Design',
    imageUrl:
      'https://9arrow.s3.amazonaws.com/images/nbi-site.jpg',
    siteName: 'nbifinancialgroup.com',
    webUrl: 'https://nbifinancialgroup.com/',
  },
  {
    name: 'FZM Properties LLC',
    role: 'Web Development and Design, Logo Design',
    imageUrl:
      'https://9arrow.s3.amazonaws.com/images/fzm-site.jpg',
    siteName: 'nbifinancialgroup.com',
    webUrl: 'https://www.fzmpropertiesllc.com/',
  },
  {
    name: 'Sacandaga Boat Charters',
    role: 'Web Development and Design, Logo Design',
    imageUrl:
      'https://9arrow.s3.amazonaws.com/images/boat-site.jpg',
    siteName: 'sacandagaboatcharters.com',
    webUrl: 'https://www.sacandagaboatcharters.com/',
  },
  // More people...
]

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our work</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Here are some of our trusted clients.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
        >
          {people.map((person) => (
            <Link to={person.webUrl} target="_blank" rel="noopener noreferrer">
            <li key={person.name}>
              <img className="aspect-[3/2] w-full rounded-2xl object-cover" src={person.imageUrl} alt="" />
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
              <p className="text-base leading-7 text-gray-600">{person.role}</p>                  
            </li></Link>
          ))}
        </ul>
      </div>
    </div>
  )
}
