import { PaintBrushIcon, WindowIcon, LockClosedIcon, PhotoIcon, NewspaperIcon, CurrencyDollarIcon } from '@heroicons/react/24/solid'

const features = [
  {
    name: 'Hosting Support and Security',
    description:
      'With our hosting support, customers can rest assured that their websites are in safe hands, allowing them to focus on their core business activities while enjoying a secure and reliable online presence.',
    href: '#',
    icon: LockClosedIcon,
  },
  {
    name: 'Custom Design',
    description:
      'Our company specializes in creating custom website designs that are tailored to meet the unique needs and goals of our clients. With a team of talented designers, we collaborate closely with our customers to craft visually stunning and user-friendly websites that leave a lasting impression and effectively communicate their brand identity.',
    href: '#',
    icon: PaintBrushIcon,
  },
  {
    name: 'SEO',
    description:
      'We offer comprehensive search engine optimization (SEO) services to our clients, ensuring their websites are optimized for higher visibility and organic search rankings. We employ industry best practices, conduct thorough keyword research, optimize on-page elements, and implement effective link building strategies to improve our clients online presence and drive targeted traffic to their websites.',
    href: '#',
    icon: WindowIcon,
  },
  {
    name: 'Custom Logo and Banners',
    description:
      'Our company understands the importance of strong branding, which is why we offer custom logo and banner design services to help our customers establish a distinctive and professional brand identity. Our team of skilled designers works closely with clients to create visually captivating logos and banners that align with their brand values and effectively convey their message, ultimately enhancing their brand recognition and leaving a memorable impression on their target audience.',
    href: '#',
    icon: PhotoIcon,
  },
  {
    name: 'E-Commerce',
    description:
      'Seamlessly integrating e-commerce functionality into our clients websites, empowering them to sell their products online with ease. We leverage robust e-commerce platforms and tailor the solution to meet their specific requirements, providing features such as secure payment gateways, inventory management, order tracking, and intuitive user interfaces to enhance the shopping experience and drive sales.',
    href: '#',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'Blog',
    description:
      'A dynamic and engaging blog feature into our clients websites, enabling them to regularly publish relevant content and engage with their audience. We utilize user-friendly content management systems and intuitive design to ensure easy blog management, allowing our clients to share valuable information, establish thought leadership, and drive organic traffic to their website.',
    href: '#',
    icon: NewspaperIcon,
  },
]

export default function Example() {
  return (
    <div className="bg-white mt-20 py-24 sm:py-32">
 
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Tailored Solutions</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Unleashing the Power of Versatile Services for Your Website
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Our website creation and marketing company offers comprehensive solutions to help customers establish a strong online presence, including designing and developing custom websites, implementing effective digital marketing strategies, and driving targeted traffic to their business.

          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <feature.icon className="h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  {/* <p className="mt-6">
                    <a href={feature.href} className="text-sm font-semibold leading-6 text-indigo-600">
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p> */}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
